import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['selected']

  initialize () {
    this.selected = new Set()
    this.hiddens = []
  }

  connect () {
    this.selected.clear()
    JSON.parse(this.element.dataset.multiselectSelected).forEach((value) => {
      this.selected.add(value)
    })

    this.update()
  }

  toggle () {
    const popoverController = this.application.getControllerForElementAndIdentifier(this.element, 'popover')

    const { popoverElement } = popoverController
    if (popoverElement == null) return

    popoverElement.addEventListener('change', (e) => {
      const { checked, value } = e.target

      if (checked) {
        this.selected.add(value)
      } else {
        this.selected.delete(value)
      }

      this.update()
    })

    Array.prototype.forEach.call(popoverElement.querySelectorAll('input[type=checkbox]'), (input) => {
      if (!this.selected.has(input.value)) return

      input.checked = true
    })
  }

  update () {
    const values = Array.from(this.selected)

    if (values.length > 0) {
      this.selectedTarget.textContent = values.join(', ')
    } else {
      this.selectedTarget.innerHTML = '&nbsp;'
    }

    this.hiddens.forEach((hidden) => {
      hidden.remove()
    })

    this.hiddens = values.map((value) => {
      const hidden = document.createElement('input')
      hidden.type = 'hidden'
      hidden.name = this.element.dataset.multiselectName
      hidden.value = value

      this.element.appendChild(hidden)

      return hidden
    })
  }
}
