import { Controller } from '@hotwired/stimulus'
import wrapIndex from 'utils/wrap_index'

const ZOOM_FACTOR = 1.2

export default class extends Controller {
  static targets = ['image', 'scrollable', 'pageControl']
  static values = {
    dragscrolling: { type: Boolean, default: false }
  }

  initialize () {
    this.boundHandleKeys = this.handleKeys.bind(this)
    this.boundResetZoom = this.resetZoom.bind(this)
    this.images = null
    this.index = null
    this.zoomLevel = 1
  }

  get currentImage () {
    return this.images[this.index]
  }

  open ({ detail: { index, images } }) {
    this.images = images
    this.index = index

    this.element.classList.add('is-opened')
    this.element.ownerDocument.body.classList.add('gallery-viewer-opened')
    this.pageControlTargets.forEach((el) => el.classList.toggle('is-disabled', images.length < 2))

    window.addEventListener('keydown', this.boundHandleKeys)
    window.addEventListener('orientationchange', this.boundResetZoom)
    document.activeElement.blur()

    this.display()
  }

  close () {
    this.element.classList.remove('is-opened')
    this.element.ownerDocument.body.classList.remove('gallery-viewer-opened')
    this.dragscrollingValue = false

    window.removeEventListener('keydown', this.boundHandleKeys)
    window.removeEventListener('orientationchange', this.boundResetZoom)
  }

  display () {
    const { src } = this.currentImage

    this.zoomLevel = 1
    this.imageTarget.setAttribute('src', '')
    this.imageTarget.style.visibility = 'hidden'
    this.applyZoom()
    setTimeout(() => this.imageTarget.setAttribute('src', src), 0)
  }

  imageLoaded () {
    this.imageTarget.style.visibility = 'visible'
  }

  prev () {
    this.index = wrapIndex(this.index - 1, this.images.length)
    this.display()
  }

  next () {
    this.index = wrapIndex(this.index + 1, this.images.length)
    this.display()
  }

  zoomOut () {
    this.zoomLevel /= ZOOM_FACTOR
    if (this.zoomLevel < 1) {
      this.zoomLevel = 1
    }
    this.applyZoom()
  }

  zoomIn () {
    this.zoomLevel *= ZOOM_FACTOR
    this.applyZoom()
  }

  resetZoom () {
    this.zoomLevel = 1
    this.applyZoom()
  }

  applyZoom () {
    let { width, height } = this.currentImage
    const containerWidth = this.element.clientWidth
    const containerHeight = this.element.clientHeight
    const imageHeight = height
    const imageWidth = width

    if (imageWidth > containerWidth || imageHeight > containerHeight) {
      const imageRatio = imageWidth / imageHeight
      const windowRatio = containerWidth / containerHeight

      if (imageRatio > windowRatio) {
        width = containerWidth
        height = containerWidth / imageWidth * imageHeight
      } else {
        height = containerHeight
        width = containerHeight / imageHeight * imageWidth
      }
    }

    height = Math.floor(height * this.zoomLevel)
    width = Math.floor(width * this.zoomLevel)

    const top = Math.max(containerHeight - height, 0) / 2
    const left = Math.max(containerWidth - width, 0) / 2

    this.imageTarget.style.width = width + 'px'
    this.imageTarget.style.height = height + 'px'
    this.imageTarget.style.transform = `translate(${left}px, ${top}px)`
  }

  handleKeys (event) {
    if (event.defaultPrevented || this.dragscrollingValue || event.ctrlKey || event.metaKey) {
      return
    }

    let handled = true

    switch (event.code) {
      case 'Escape':
        this.close()
        break
      case 'NumpadAdd':
      case 'Equal':
        this.zoomIn()
        break
      case 'NumpadSubtract':
      case 'Minus':
        this.zoomOut()
        break
      case 'ArrowLeft':
        this.prev()
        break
      case 'ArrowRight':
        this.next()
        break
      default:
        handled = false
    }

    if (handled) {
      event.stopPropagation()
      event.preventDefault()
    }
  }

  startDragscroll (event) {
    this.dragscrollingValue = true

    this.lastClientX = event.clientX
    this.lastClientY = event.clientY

    event.preventDefault()
  }

  dragscroll (event) {
    if (!this.dragscrollingValue) {
      return
    }

    this.scrollableTarget.scrollLeft -= event.clientX - this.lastClientX
    this.scrollableTarget.scrollTop -= event.clientY - this.lastClientY

    this.lastClientX = event.clientX
    this.lastClientY = event.clientY
  }

  stopDragscroll () {
    this.dragscrollingValue = false
  }
}
