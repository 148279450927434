import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['listWrapper', 'list', 'item']

  initialize () {
    this.isMoving = false
    this.itemWidth = this.itemTarget.offsetWidth + parseInt(window.getComputedStyle(this.itemTarget).marginRight)

    this.boundDisableNav = this.disableNav.bind(this)
    this.boundHandleTransitionEnd = this._handleTransitionEnd.bind(this)
  }

  connect () {
    this.disableNav()

    window.addEventListener('resize', this.boundDisableNav)

    this.listTarget.addEventListener('transitioncancel', this.boundHandleTransitionEnd)
    this.listTarget.addEventListener('transitionend', this.boundHandleTransitionEnd)
  }

  disconnect () {
    window.removeEventListener('resize', this.boundDisableNav)

    this.listTarget.removeEventListener('transitioncancel', this.boundHandleTransitionEnd)
    this.listTarget.removeEventListener('transitionend', this.boundHandleTransitionEnd)
  }

  disableNav () {
    const isScrollable = this.listWrapperTarget.clientWidth < this.itemWidth * this.itemTargets.length

    this.element.classList.toggle('is-scrollable', isScrollable)
  }

  next () {
    if (this.isMoving) return

    this.isMoving = true
    this.listTarget.appendChild(this.itemTarget.cloneNode(true))

    this.listTarget.classList.add('is-moving')
    this.listTarget.style.transform = `translateX(-${this.itemWidth}px)`
    // force reflow
    // eslint-disable-next-line no-unused-expressions
    this.listTarget.offsetHeight
  }

  cleanup () {
    if (!this.isMoving) return

    this.listTarget.classList.remove('is-moving')
    this.listTarget.removeChild(this.listTarget.firstChild)
    this.listTarget.style.transform = null

    this.isMoving = false
  }

  startSpin () {
    if (!this.spin) {
      setTimeout(this.next.bind(this), 0)
    }
    this.spin = true
  }

  stopSpin () {
    this.spin = false
  }

  _handleTransitionEnd (event) {
    if (event.target !== this.listTarget) return

    this.cleanup()

    if (this.spin) {
      setTimeout(this.next.bind(this), 0)
    }
  }
}
