import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['image']

  initialize () {
    this.images = []
  }

  disconnect () {
    this.images.splice(0)
  }

  imageTargetConnected (el) {
    const src = el.dataset.src || el.src
    const width = el.dataset.width || el.width
    const height = el.dataset.height || el.height

    this.images.push({ src, width: Number(width), height: Number(height) })
  }

  open ({ params: { index } }) {
    this.dispatch('open', { detail: { index, images: this.images } })
  }
}
