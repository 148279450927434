import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  enable ({ params: { target } }) {
    const el = this.element.ownerDocument.getElementById(target)

    el.removeAttribute('hidden')
    el.removeAttribute('disabled')
  }

  disable ({ params: { target } }) {
    const el = this.element.ownerDocument.getElementById(target)

    el.setAttribute('hidden', '')
    el.setAttribute('disabled', '')
  }
}
