import { Controller } from '@hotwired/stimulus'

const TEXTS = {
  '': "Don't change",
  add: 'Add',
  remove: 'Remove'
}

export default class extends Controller {
  static values = {
    current: String,
    operations: Array
  }

  change () {
    const selectedValue = this.currentValue.toLowerCase()
    const selected = this.operationsValue.findIndex((v) => v === selectedValue)

    if (selected === this.operationsValue.length - 1) {
      this.currentValue = this.operationsValue[0]
    } else {
      this.currentValue = this.operationsValue[selected + 1]
    }
  }

  currentValueChanged (value, previousValue) {
    if (previousValue === undefined) return

    this.element.setAttribute('aria-label', TEXTS[value])
    this.dispatch('change', { detail: { operation: value, value: this.element.value } })
  }
}
