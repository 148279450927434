import { Controller } from '@hotwired/stimulus'

// <form ... data-controller="selectable-paginated-list" data-selectable-paginated-list-selected-value="[1,2,3]">
//   <input type="hidden" name="parent[add_child_id][]" value="" data-selectable-paginated-list-target="addInput">
//   ...
//   <input type="hidden" name="parent[add_child_id][]" value="42" data-selectable-paginated-list-target="addInput">
//   <input type="hidden" name="parent[remove_child_id][]" value="" data-selectable-paginated-list-target="removeInput">
//   ...
//   <input type="hidden" name="parent[remove_child_id][]" value="1" data-selectable-paginated-list-target="removeInput">
// </form>

export default class extends Controller {
  static targets = ['toggle', 'addInput', 'removeInput']
  static values = {
    selected: Array
  }

  initialize () {
    this.selectedSet = new Set()
    this.addInputsMap = new Map()
    this.removeInputsMap = new Map()
    this.selectedValue.forEach((value) => this.selectedSet.add(String(value)))
  }

  toggleTargetConnected (el) {
    const id = String(el.value)

    if (this.removeInputsMap.has(id)) {
      el.checked = false
    } else if (this.addInputsMap.has(id) || this.selectedSet.has(id)) {
      el.checked = true
    }
  }

  disconnect () {
    this.addInputsMap.clear()
    this.removeInputsMap.clear()
  }

  toggle (event) {
    const el = event.currentTarget
    const value = String(el.value)

    if (el.checked) {
      this.add(value)
    } else {
      this.remove(value)
    }
  }

  add (value) {
    if (this.selectedSet.has(value)) {
      const el = this.removeInputTargets.find((el) => el.value === value)
      el?.remove()
      this.removeInputsMap.delete(value)
    } else {
      const newEl = this.addInputTarget.cloneNode()
      newEl.value = value

      this.addInputTarget.insertAdjacentElement('afterend', newEl)
      this.addInputsMap.set(value, newEl)
    }
  }

  remove (value) {
    if (this.selectedSet.has(value)) {
      const newEl = this.removeInputTarget.cloneNode()
      newEl.value = value

      this.removeInputTarget.insertAdjacentElement('afterend', newEl)
      this.removeInputsMap.set(value, newEl)
    } else {
      const el = this.addInputTargets.find((el) => el.value === value)
      el?.remove()
      this.addInputsMap.delete(value)
    }
  }
}
