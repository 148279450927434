import { Controller } from '@hotwired/stimulus'

const DEFAULT_HEIGHT = 90

export default class extends Controller {
  static targets = ['content', 'toggle']
  static values = {
    opened: Boolean,
    maxHeight: Number
  }

  connect () {
    this.isCollapsable = this.contentTarget.scrollHeight > this.maxHeightValue
    this.applyState()
  }

  initialize () {
    if (!this.hasMaxHeightValue) {
      this.maxHeightValue = DEFAULT_HEIGHT
    }
  }

  applyState () {
    if (this.isCollapsable) {
      this.toggleTarget.style.display = 'unset'
      this.contentTarget.classList.add('collapsable')
    } else {
      this.toggleTarget.style.display = 'none'
      this.contentTarget.classList.remove('collapsable')
    }

    this.contentTarget.classList.toggle('is-collapsed', this.isCollapsable && !this.openedValue)

    if (this.openedValue) {
      this.contentTarget.style.maxHeight = null
      this.toggleTarget.innerText = 'Show less'
    } else {
      this.contentTarget.style.maxHeight = this.maxHeightValue + 'px'
      this.toggleTarget.innerText = 'Show more'
    }
  }

  toggle (event) {
    event.preventDefault()
    this.openedValue = !this.openedValue
  }

  openedValueChanged () {
    this.applyState()
  }
}
