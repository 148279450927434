import { Controller } from '@hotwired/stimulus'
import format from 'date-fns/format'
import parseJSON from 'date-fns/parseJSON'
import formatTime from 'bookfusion-ui/javascript/formatTime'

export default class extends Controller {
  connect () {
    if (this.element.dataset.keepContent == null) {
      this.element.textContent = this.formatText()
    }
    this.element.title = this.formatTitle()
  }

  getValue () {
    let value

    if (this.element.tagName === 'TIME') {
      value = this.element.dateTime
    } else {
      value = this.element.dataset.dateTime
    }

    return parseJSON(value)
  }

  formatText () {
    const text = formatTime(this.getValue())
    return text.charAt(0).toUpperCase() + text.slice(1)
  }

  formatTitle () {
    return format(this.getValue(), 'Pp')
  }
}
