import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle']

  connect () {
    this.isOpened = this.toggleTarget.getAttribute('aria-expanded') === 'true'
  }

  toggle (event) {
    this.isOpened = !this.isOpened
    const target = event.currentTarget
    const controlsId = target.getAttribute('aria-controls')
    const content = this.element.ownerDocument.getElementById(controlsId)

    this.element.classList.toggle('is-opened', this.isOpened)
    target.setAttribute('aria-expanded', this.isOpened.toString())

    if (this.isOpened) {
      content.removeAttribute('hidden')
    } else {
      content.setAttribute('hidden', '')
    }

    if (content.tagName === 'FIELDSET') {
      if (this.isOpened) {
        content.removeAttribute('disabled')
      } else {
        content.setAttribute('disabled', '')
      }
    }
  }
}
