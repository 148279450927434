/* globals $, evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    settings: Object
  }

  initialize () {
    this.handleClick = this.open.bind(this)
  }

  connect () {
    this.element.addEventListener('click', this.handleClick)
  }

  disconnect () {
    this.element.removeEventListener('click', this.handleClick)
  }

  open (event) {
    event.preventDefault()

    const template = document.querySelector(this.settingsValue.template)
    const modal = template.content.cloneNode(true).firstChild

    modal.id = this.settingsValue.id

    document.body.appendChild(modal)
    evil.block.vitalize(modal)

    const $modal = $(modal)

    $modal.trigger('open')

    this.loadContent($modal)

    $modal.one('close', () => {
      if (!this.currentRequest) return
      this.currentRequest.abort()
    })

    $modal.one('closed', function () {
      $(this).remove()
    })
  }

  loadContent ($modal) {
    this.currentRequest = $.rails.ajax({
      url: this.settingsValue.url,
      dataType: 'html',
      success: (data) => {
        $modal.find('@content').html(data)
        evil.block.vitalize($modal)
      },
      complete: () => {
        this.currentRequest = null
      }
    })
  }
}
