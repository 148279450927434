import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['tab', 'tabContent', 'cursor']
  static values = {
    current: String,
    mode: { type: String, default: 'horizontal' }
  }

  initialize () {
    this.resizeObserver = new ResizeObserver(() => {
      this.updateCursor()
    })
  }

  connect () {
    this.resizeObserver.observe(this.element)
  }

  disconnect () {
    this.resizeObserver.unobserve(this.element)
  }

  currentValueChanged () {
    this.tabTargets.forEach((el, index) => {
      const isSelected = el.dataset.tabId === this.currentValue

      el.setAttribute('aria-selected', isSelected)
      el.classList.toggle('is-active', isSelected)
    })

    this.tabContentTargets.forEach((el) => el.classList.toggle('is-active', el.dataset.tabId === this.currentValue))
    this.updateCursor()
  }

  activate (event) {
    if (event.target.dataset.tabId == null) return

    this.currentValue = event.target.dataset.tabId
  }

  updateCursor () {
    if (this.hasCursorTarget) {
      this.moveCursor(this.currentValue)
    }
  }

  moveCursor (tabId) {
    const currentTab = this.tabTargets.find((el) => el.dataset.tabId === tabId)

    if (!currentTab) return

    switch (this.modeValue) {
      case 'horizontal': {
        const { offsetLeft, offsetWidth } = currentTab

        this.cursorTarget.style.left = offsetLeft + 'px'
        this.cursorTarget.style.width = offsetWidth + 'px'

        break
      }
      case 'vertical': {
        const { offsetTop, offsetHeight } = currentTab

        this.cursorTarget.style.top = offsetTop + 'px'
        this.cursorTarget.style.height = offsetHeight + 'px'

        break
      }
    }
  }

  receiveNotification (event) {
    const { tabId } = event.currentTarget.dataset

    const currentTab = this.tabTargets.find((el) => el.dataset.tabId === tabId)

    if (event.detail) {
      currentTab.dataset.counter++
    } else {
      currentTab.dataset.counter--
    }

    currentTab.classList.toggle('is-notified', Number(currentTab.dataset.counter) > 0)
  }
}
