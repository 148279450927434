/* globals MessageBus */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  initialize () {
    this.boundBookProcessed = this.bookProcessed.bind(this)
  }

  connect () {
    MessageBus.subscribe('/book_processed', this.boundBookProcessed)
  }

  disconnect () {
    MessageBus.unsubscribe('/book_processed', this.boundBookProcessed)
  }

  bookProcessed (data) {
    const el = this.element.querySelector(`[data-id="ownership_${data.ownership_id}"]`)

    if (el.dataset.reloadUrl) {
      $.getScript(el.dataset.reloadUrl)
    }
  }
}
