/* globals Dropzone */

import { Controller } from '@hotwired/stimulus'
import preventUnload from 'utils/prevent_unload'

const MAX_FILES = 10

export default class extends Controller {
  static targets = [
    'addBooksButton',
    'addImagesButton',
    'submitButton',
    'selectedBooks',
    'selectedBooksList',
    'selectedImages',
    'selectFile',
    'previewTemplate',
    'previewsContainer',
    'imagesLimit'
  ]

  static values = {
    selectedBooks: { type: Array, default: [] },
    selectedImages: { type: Array, default: [] },
    acceptedFiles: String,
    imagesEnabled: { type: Boolean, default: false }
  }

  static outlets = [
    'carousel'
  ]

  // Callbacks
  initialize () {
    this._isConnected = false
  }

  connect () {
    this._isConnected = true

    this.element.addEventListener('submit', () => {
      $(this.element).closest('@@modal').trigger('disableConfirm')
    })

    this.dropzone = new Dropzone(this.selectedImagesTarget, {
      url: '/post_image_uploads',
      clickable: this.selectFileTargets,
      acceptedFiles: this.acceptedFilesValue,
      withCredentials: true,
      paramName: 'resource',
      thumbnailWidth: 500,
      thumbnailHeight: null,
      headers: {
        'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]').getAttribute('content')
      },
      previewsContainer: this.previewsContainerTarget,
      previewTemplate: this.previewTemplateTarget,
      maxFiles: MAX_FILES,
      accept: (file, done) => {
        const { dictMaxFilesExceeded, maxFiles } = this.dropzone.options

        if (this.dropzone.getActiveFiles().length + this.selectedImagesValue.length >= maxFiles) {
          done(dictMaxFilesExceeded.replace('{{maxFiles}}', maxFiles))
          return this.dropzone.emit('maxfilesexceeded', file)
        }

        done()
      },
      addedfile (file) {
        // HACK: Overrides vendor/assets/javascripts/dropzone.js:251 to remove files without confirmation
        file.previewElement = this.options.previewTemplate.content.cloneNode(true).firstChild
        file.previewTemplate = file.previewElement
        this.previewsContainer.appendChild(file.previewElement)

        for (const removeLink of file.previewElement.querySelectorAll('[data-dz-remove]')) {
          removeLink.addEventListener('click', (event) => {
            event.preventDefault()
            event.stopPropagation()
            this.removeFile(file)
          })
        }
      },
      addedfiles: () => {
        this.numberOfImagesChanged()
      },
      removedfile: (file) => {
        if (!this._isConnected) return
        this.removeImage({ params: { imageId: file.imageId } })
      }
    })

    this.dropzone.on('success', (file, response) => {
      const inputEl = file.previewElement.querySelector('input[type="hidden"]')

      if (inputEl) {
        inputEl.value = response.id
      }

      file.imageId = +response.id
      this.selectedImagesValue = this.selectedImagesValue.concat(+response.id)
    })

    this.dropzone.on('error', (file) => {
      const inputEl = file.previewElement.querySelector('input[type="hidden"]')

      if (inputEl) {
        inputEl.remove()
      }
    })

    this.dropzone.on('addedfile', (file) => {
      this.submitButtonTargets.forEach((el) => {
        el.disabled = true
      })

      this.carouselOutlet.switchToLast()
    })

    this.dropzone.on('queuecomplete', () => {
      this.submitButtonTargets.forEach((el) => {
        el.disabled = false
      })
    })
  }

  disconnect () {
    this._isConnected = false

    window.removeEventListener('beforeunload', preventUnload)
    this.dropzone.destroy()
  }

  selectedBooksValueChanged (value) {
    const hasSelectedBooks = Array.isArray(value) && value.length

    this.addBooksButtonTarget.classList.toggle('is-active', hasSelectedBooks)
    this.selectedBooksTarget.classList.toggle('is-active', hasSelectedBooks)
    this.selectedBooksListTarget.classList.toggle('is-multiple', value.length > 1)
  }

  selectedImagesValueChanged () {
    this.numberOfImagesChanged()
  }

  imagesEnabledValueChanged (value) {
    this.addImagesButtonTarget.classList.toggle('is-active', value)
    this.selectedImagesTarget.classList.toggle('is-active', value)
  }

  // Actions

  toggleImages () {
    if (this.selectedImagesValue.length) {
      this.imagesEnabledValue = true
    } else {
      this.imagesEnabledValue = !this.imagesEnabledValue
    }
  }

  removeImage ({ params: { imageId } }) {
    this.selectedImagesValue = this.selectedImagesValue.filter((value) => value !== imageId)

    if (this.hasCarouselOutlet) {
      this.carouselOutlet.removeActiveSlide()
    }

    this.numberOfImagesChanged()
  }

  // Methods

  changeBooks (addBooks, removeBooks) {
    const ids = new Set(this.selectedBooksValue)

    for (const [id, template] of addBooks) {
      ids.add(id)
      this.selectedBooksListTarget.insertAdjacentHTML('beforeend', template)
    }

    for (const id of removeBooks.keys()) {
      ids.delete(id)
      this.selectedBooksListTarget.querySelector(`[data-id="${id}"]`).remove()
    }

    this.selectedBooksValue = Array.from(ids)
  }

  numberOfImagesChanged () {
    let activeItemsCount = 0

    if (this.dropzone) {
      activeItemsCount = this.dropzone.getActiveFiles().length
    }

    const imagesCount = activeItemsCount + this.selectedImagesValue.length
    const rest = MAX_FILES - imagesCount

    if (this.hasImagesLimitTarget) {
      this.imagesLimitTarget.textContent = rest.toString()
    }

    this.element.classList.toggle('is-full-of-images', rest <= 0)

    if (imagesCount > 0) {
      $(this.element).closest('@@modal').trigger('enableConfirm', 'Are you sure you want to close modal?')
      window.addEventListener('beforeunload', preventUnload)
    } else {
      $(this.element).closest('@@modal').trigger('disableConfirm')
      window.removeEventListener('beforeunload', preventUnload)
    }
  }
}
