import { Controller } from '@hotwired/stimulus'
import { openPopup } from '../utils/share'

export default class extends Controller {
  share ({ params }) {
    if (!params.url) return

    const shareUrl = 'https://www.pinterest.com/pin-builder/' +
      `?url=${window.encodeURIComponent(params.url)}` +
      `&media=${window.encodeURIComponent(params.media)}` +
      `&description=${window.encodeURIComponent(params.description)}`

    openPopup(shareUrl, { width: 1050, height: 880 })
  }
}
