import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'modalTemplate',
    'visibilityInput', // <input type="hidden">
    'friendListInput', // <input type="hidden">
    'buttonText'
  ]

  disconnect () {
    this.modalElement?.remove()
  }

  modalTemplateTargetConnected (el) {
    this.modalElement = this.modalTemplateTarget.content.cloneNode(true).firstChild
    document.body.appendChild(this.modalElement)
    this.modalElement.addEventListener('visibility-settings--modal:confirmed', (e) => this.confirm(e))
  }

  open () {
    this.dispatch('open', { target: this.modalElement })
  }

  confirm ({ detail: { visibility, label, friendListIds } }) {
    this.visibilityInputTarget.value = visibility
    this.buttonTextTarget.textContent = label

    this.friendListInputTargets.slice(1).forEach((el) => el.remove())
    friendListIds.forEach((value) => {
      const newEl = this.friendListInputTarget.cloneNode()
      newEl.value = value
      this.friendListInputTarget.insertAdjacentElement('afterend', newEl)
    })
  }
}
