/* globals $, evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  disconnect () {
    if (this.currentRequest != null) {
      this.currentRequest.abort()
      this.currentRequest = null
    }
  }

  loadComments () {
    if (this.currentRequest != null) {
      return
    }

    this.currentRequest = $.rails.ajax({
      url: this.element.href,
      dataType: 'html',
      beforeSend: () => {
        this.element.classList.add('is-loading')
      },
      success: (responseText) => {
        const newContent = $(responseText)

        $(this.element).replaceWith(newContent)
        evil.block.vitalize(newContent)
      },
      complete: () => {
        this.element?.isConnected && this.element.classList.remove('is-loading')
        this.currentRequest = null
      }
    })
  }
}
