import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['message', 'checkbox', 'button']
  static classes = ['error']

  connect () {
    if (!this.checkboxTarget.checked) {
      this.messageTarget.classList.add(this.errorClass)
    }
  }

  check (event) {
    if (event.target.checked) {
      this.messageTarget.classList.remove(this.errorClass)
    }
  }

  validate (event) {
    if (!this.checkboxTarget.checked) {
      event.preventDefault()
      event.stopPropagation()

      this.buttonTarget.dispatchEvent(new CustomEvent('loader:reset', { bubbles: true }))
      this.messageTarget.classList.add(this.errorClass)
    }
  }
}
