/* globals evil, $ */

import { Controller } from '@hotwired/stimulus'

function cueMarkObserverCallback (entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      this.requestPage(entry.target.getAttribute('href'))
    }
  })
}

export default class extends Controller {
  static targets = [
    'list',
    'item',
    'cueMark',
    'loader',
    'counter',
    'submit',
    'errorMessage'
  ]

  static outlets = [
    'post-form'
  ]

  initialize () {
    this.addBooks = new Map()
    this.removeBooks = new Set()
  }

  // Callbacks

  connect () {
    this.selectedBooks = new Set(this.postFormOutlet.selectedBooksValue)
    this.cueMarkObserver = new IntersectionObserver(cueMarkObserverCallback.bind(this))
    this.requestPage(this.element.dataset.url)
    this.updateCounter()
  }

  disconnect () {
    this.cueMarkObserver.disconnect()
    clearTimeout(this.throttleTimeout)
    this.stopRequest()
  }

  itemTargetConnected (el) {
    const id = Number(el.dataset.id)
    const checkbox = el.querySelector('[type="checkbox"]')

    if (this.removeBooks.has(id)) {
      checkbox.checked = false
    } else if (this.addBooks.has(id) || this.selectedBooks.has(id)) {
      checkbox.checked = true
    }
  }

  // Methods

  cleanList () {
    if (this.hasCueMarkTarget) {
      this.cueMarkObserver.unobserve(this.cueMarkTarget)
    }

    const listEl = this.listTarget

    while (listEl.firstChild) {
      listEl.removeChild(listEl.firstChild)
    }
  }

  updateCounter () {
    if (this.hasCounterTarget) {
      const size = this.selectedBooks.size - this.removeBooks.size + this.addBooks.size

      this.counterTarget.textContent = size || null
    }
  }

  toggleItem (event) {
    const id = Number(event.target.value)

    if (this.selectedBooks.has(id)) {
      this.removeBooks.has(id) ? this.removeBooks.delete(id) : this.removeBooks.add(id)
    } else {
      this.addBooks.has(id) ? this.addBooks.delete(id) : this.addBooks.set(id, event.currentTarget.dataset.template)
    }

    this.updateCounter()
  }

  submit () {
    if (this.hasPostFormOutlet) {
      this.postFormOutlet.changeBooks(this.addBooks, this.removeBooks)
    }
  }

  search (event) {
    clearTimeout(this.throttleTimeout)
    this.cleanList()
    this.throttleTimeout = setTimeout(() => {
      this.requestPage(this.element.dataset.url, event.target.value)
    }, 200)
  }

  requestPage (url, query = null) {
    if (!url) throw Error('URL is not provided')

    if (!this.currentRequest) {
      if (this.hasLoaderTarget) {
        this.loaderTarget.style.display = 'block'
      }

      if (this.hasErrorMessageTarget) {
        this.errorMessageTarget.classList.remove('is-visible')
      }

      this.currentRequest = $.ajax({
        url,
        data: { query },
        success: (responseText) => {
          if (this.hasCueMarkTarget) {
            this.cueMarkObserver.unobserve(this.cueMarkTarget)
            this.cueMarkTarget.remove()
          }

          this.listTarget.insertAdjacentHTML('beforeend', responseText)

          if (this.hasCueMarkTarget) {
            this.cueMarkObserver.observe(this.cueMarkTarget)
          }

          if (this.hasErrorMessageTarget) {
            this.errorMessageTarget.classList.toggle('is-visible', !this.hasItemTarget)
          }

          evil.block.vitalize(this.listTarget)
        },
        complete: () => {
          this.currentRequest = null
          this.loaderTarget.style.display = 'none'
        }
      })
    }

    return this.currentRequest
  }

  stopRequest () {
    this.currentRequest && this.currentRequest.abort()
  }
}
