import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['form']

  formTargetConnected (el) {
    this.element.classList.add('is-editing')
    this.application.getControllerForElementAndIdentifier(el, 'comment-form').focus()
  }

  formTargetDisconnected () {
    this.element.classList.remove('is-editing')
  }

  cancelEdit () {
    $(this.formTarget).remove()
  }

  reply ({ params }) {
    const el = document.querySelector(params.replyForm)
    el.classList.remove('is-hidden')

    setTimeout(() => {
      const commentFormController = this.application.getControllerForElementAndIdentifier(el, 'comment-form')

      if (params.username) {
        commentFormController.prependMention(params.username)
      }

      commentFormController.focus()
      commentFormController.element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }, 0)
  }
}
