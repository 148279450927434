import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String
  }

  targetValueChanged (value) {
    if (value) {
      this.targetElement = document.querySelector(value)
      this.isVisible = getComputedStyle(this.targetElement).display !== 'none'
    } else {
      this.targetElement = null
      this.isVisible = null
    }
  }

  toggle (event) {
    event.stopPropagation()

    if (this.isVisible) {
      this.hide()
    } else {
      this.show()
    }
  }

  show () {
    if (this.isVisible || !this.targetElement) return

    this.targetElement.style.display = 'unset'
    this.isVisible = true
  }

  hide (event) {
    if (!this.isVisible || !this.targetElement) return
    if (event && this.targetElement.contains(event.target)) return

    this.targetElement.style.display = 'none'
    this.isVisible = false
  }
}
