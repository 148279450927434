import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input', 'icon']

  toggle () {
    const isHidden = this.inputTarget.type === 'password'

    this.inputTarget.type = isHidden ? 'text' : 'password'
    this.inputTarget.focus()

    if (this.hasIconTarget) {
      const iconHref = isHidden ? '#icon-eye-solid' : '#icon-eye-slash-solid'

      this.iconTarget.querySelector('use').setAttributeNS(null, 'href', iconHref)
    }
  }
}
