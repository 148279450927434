import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  /*
    <a ... data-controller="visit-confirmation"
           data-action="click->visit-confirmation#confirm"
           data-visit-confirmation-text-param="Are you sure?"></a>
   */
  confirm (event) {
    const message = event.params.text || 'Are you sure?'

    if (!confirm(message)) {
      event.preventDefault()
    }
  }
}
