import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'
import { Picker } from 'emoji-picker-element'

const CUSTOM_STYLE = `
.picker {
  border-radius: 6px;
  box-shadow: 0 6px 10px rgba(25, 29, 68, 0.1);
}

.indicator {
  border-radius: 3px;
  bottom: -2px;
  position: absolute;
}

.indicator-wrapper {
  margin-bottom: 2px;
  position: relative;
}

.favorites {
  border-top: 1px solid var(--border-color);
}

.search-wrapper {
  align-items: center;
  background: rgba(133, 136, 159, 0.1);
  border-radius: 20px;
  border: 1px solid transparent;
  box-sizing: border-box;
  display: flex;
  padding: 0 16px;
}

.search-wrapper:focus,
.search-wrapper:focus-within {
  border-color: rgba(44, 122, 255, 0.4);
}

input.search {
  background: none;
  border: none;
  box-shadow: none;
  box-sizing: border-box;
  color: var(--text-color);
  font-family: Montserrat, sans-serif;
  height: 30px;
  margin: 0;
  outline: none;
  width: 100%;
}

input.search:focus {
  outline: none;
  box-shadow: none;
}

input.search::placeholder {
  color: #85889f;
  opacity: 1;
}
`

export default class extends Controller {
  static targets = [
    'button',
    'dialog'
  ]

  static values = {
    opened: { type: Boolean, default: false },
    options: Object
  }

  initialize () {
    this.boundOutsideClickHandler = this.outsideClickHandler.bind(this)
  }

  connect () {
    this.popper = createPopper(this.buttonTarget, this.dialogTarget, {
      modifiers: [{
        name: 'flip'
      }, {
        name: 'offset',
        options: {
          offset: ({ placement }) => {
            const { offset } = this.optionsValue

            if (offset) {
              const skidding = placement.split('-')[1] === 'end' ? offset[0] * -1 : offset[0]
              const distance = offset[1]

              return [skidding, distance]
            } else {
              return []
            }
          }
        }
      }]
    })
  }

  disconnect () {
    this.popper.destroy()
    this.popper = null

    if (this.picker) {
      this.picker.remove()
      this.picker = null
    }

    this.close()
  }

  _initPicker () {
    if (this.picker) return

    this.picker = new Picker({
      dataSource: '/emoji/en.json'
    })
    this.picker.classList.add('light')

    const style = document.createElement('style')
    style.textContent = CUSTOM_STYLE
    this.picker.shadowRoot.appendChild(style)

    this.dialogTarget.appendChild(this.picker)
  }

  toggle () {
    if (this.openedValue) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.openedValue = true

    this._initPicker()

    setTimeout(() => {
      document.addEventListener('click', this.boundOutsideClickHandler)
    }, 0)

    this.popper.update()
    this.dialogTarget.classList.add('is-visible')
  }

  close () {
    this.openedValue = false

    this.dialogTarget.classList.remove('is-visible')

    document.removeEventListener('click', this.boundOutsideClickHandler)
  }

  outsideClickHandler (event) {
    if (event.target.tagName !== 'EMOJI-PICKER') {
      this.close()
    }
  }

  insertEmoji (event) {
    const input = document.querySelector(this.element.dataset.input)
    const emoji = event.detail.unicode

    if (input.setRangeText) {
      input.setRangeText(emoji, input.selectionStart, input.selectionEnd, 'end')
    } else {
      const beforeSelection = input.value.slice(0, input.selectionStart)
      const afterSelection = input.value.slice(input.selectionEnd)

      input.value = beforeSelection + emoji + afterSelection
    }
  }
}
