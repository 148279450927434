import { Controller } from '@hotwired/stimulus'
import { openPopup } from '../utils/share'

export default class extends Controller {
  share ({ params }) {
    if (!params.url) return

    const shareUrl = 'https://twitter.com/intent/tweet' +
      '?via=BookFusionHQ' +
      `&url=${window.encodeURIComponent(params.url)}` +
      `&text=${window.encodeURIComponent(params.message)}`

    openPopup(shareUrl)
  }
}
