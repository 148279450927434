import { Controller } from '@hotwired/stimulus'

function copyToClipboard (text) {
  const textarea = document.createElement('textarea')
  const { style } = textarea

  style.position = 'fixed'
  style.top = 0
  style.left = 0
  style.width = '2em'
  style.height = '2em'
  style.padding = 0
  style.border = 'none'
  style.outline = 'none'
  style.boxShadow = 'none'
  style.background = 'transparent'

  textarea.value = text

  document.body.appendChild(textarea)
  textarea.focus()
  textarea.select()
  document.execCommand('copy')
  document.body.removeChild(textarea)
}

export default class extends Controller {
  copy ({ params: { text } }) {
    clearTimeout(this.timeoutId)

    copyToClipboard(text)
    this.element.classList.add('is-copied')

    this.timeoutId = setTimeout(() => this.element.classList.remove('is-copied'), 5000)
  }
}
