import { Controller } from '@hotwired/stimulus'

// <div data-controller="bulk-edit-input" data-action="bulk-edit-option:change->bulk-edit-input#optionChange">
//   <input type='hidden' name="add_element_ids[]" value="" data-bulk-edit-input-target="addInput">
//   <input type='hidden' name="remove_element_ids[]" value="" data-bulk-edit-input-target="removeInput">
// </div>
export default class extends Controller {
  static targets = ['addInput', 'removeInput']

  connect () {
    this.addInputsMap = new Map()
    this.removeInputsMap = new Map()
  }

  disconnect () {
    this.addInputsMap.clear()
    this.removeInputsMap.clear()
  }

  optionChange ({ detail: { operation, value } }) {
    let input

    switch (operation) {
      case 'add':
        if (this.addInputsMap.get(value) == null) {
          input = this.cloneHiddenInput(this.addInputTarget, value)
          this.addInputsMap.set(value, input)
          this.addInputTarget.insertAdjacentElement('afterend', input)
        }
        input = this.removeInputsMap.get(value)
        if (input) {
          this.removeInputsMap.delete(value)
          input.remove()
        }
        break
      case 'remove':
        input = this.addInputsMap.get(value)
        if (input) {
          this.addInputsMap.delete(value)
          input.remove()
        }
        if (this.removeInputsMap.get(value) == null) {
          input = this.cloneHiddenInput(this.removeInputTarget, value)
          this.removeInputsMap.set(value, input)
          this.removeInputTarget.insertAdjacentElement('afterend', input)
        }
        break
      default:
        input = this.addInputsMap.get(value)
        if (input) {
          this.addInputsMap.delete(value)
          input.remove()
        }
        input = this.removeInputsMap.get(value)
        if (input) {
          this.removeInputsMap.delete(value)
          input.remove()
        }
    }
  }

  cloneHiddenInput (input, value) {
    const newInput = input.cloneNode()
    newInput.value = value

    return newInput
  }
}
