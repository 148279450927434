import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['entry', 'addEntry', 'entryTemplate']

  initialize () {
    this.query = ''
    this.hasExactMatch = false
  }

  search (event) {
    this.query = event.target.value.trim()
    this.hasExactMatch = false

    const token = this.query.toLowerCase()

    if (this.query.length) {
      this.entryTargets.forEach((el) => {
        const text = el.textContent.toLowerCase()

        if (text.includes(token)) {
          if (text === token) {
            this.hasExactMatch = true
          }

          el.style.display = null
        } else {
          el.style.display = 'none'
        }
      })

      if (this.hasAddEntryTarget) {
        this.addEntryTarget.classList.toggle('is-hidden', this.hasExactMatch)
        const textEl = this.addEntryTarget.querySelector('label') || this.addEntryTarget
        textEl.textContent = `Add "${this.query}"`
      }
    } else {
      this.entryTargets.forEach((el) => (el.style.display = null))

      if (this.hasAddEntryTarget) {
        this.addEntryTarget.classList.add('is-hidden')
      }
    }
  }

  add () {
    const fragment = this.entryTemplateTarget.content.cloneNode(true)
    const id = Date.now()
    const label = fragment.querySelector('[data-template-label]')
    const input = fragment.querySelector('[data-template-value]')

    if (label.tagName === 'LABEL') {
      label.setAttribute('for', id)
      label.setAttribute('title', this.query)
      input.id = id
    }

    label.textContent = this.query
    input.value = this.query

    delete label.dataset.templateLabel
    delete input.dataset.templateValue

    const insertBeforeNode = this.entryTargets.find((el) => el.textContent > this.query)

    if (insertBeforeNode) {
      this.addEntryTarget.parentElement.insertBefore(fragment.firstChild, insertBeforeNode)
    } else {
      this.addEntryTarget.parentElement.appendChild(fragment.firstChild)
    }

    this.addEntryTarget.classList.add('is-hidden')
  }
}
