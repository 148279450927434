import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.previousElementSibling.style.display = 'none'
  }

  activate () {
    this.element.previousElementSibling.style.display = ''
    this.element.previousElementSibling.querySelector('textarea').focus()
    document.getElementById('smart-query-filters').innerHTML = ''
    this.element.remove()
  }
}
