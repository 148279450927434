import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  submit () {
    if (window.mixpanel?.get_distinct_id == null) return

    this.inputTarget.value = window.mixpanel.get_distinct_id()
  }
}
