import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  reset ({ params: { input, value } }) {
    const el = this.element.ownerDocument.getElementById(input)

    if (el.value !== value) {
      el.value = value
      el.dispatchEvent(new InputEvent('input', { bubbles: true }))
      el.dispatchEvent(new Event('change', { bubbles: true }))
    }
  }
}
