import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['anchor', 'message']
  static values = {
    message: String
  }

  disconnect () {
    this.close()
  }

  open () {
    const { left, bottom, width } = (this.hasAnchorTarget ? this.anchorTarget : this.element).getBoundingClientRect()
    const { pageXOffset, pageYOffset } = this.element.ownerDocument.defaultView
    const tooltip = document.createElement('div')

    tooltip.classList.add('tooltip')
    tooltip.classList.add('is-left-aligned')
    tooltip.setAttribute('role', 'tooltip')

    if (this.hasMessageTarget) {
      tooltip.innerHTML = this.messageTarget.innerHTML
    } else if (this.hasMessageValue) {
      tooltip.innerHTML = this.messageValue
    }

    document.body.appendChild(tooltip)

    tooltip.style.left = `${left + width / 2 + pageXOffset}px`
    tooltip.style.top = `${bottom + pageYOffset}px`

    tooltip.classList.add('is-visible')

    this.tooltip = tooltip
  }

  close () {
    if (this.tooltip) {
      this.tooltip.remove()
    }

    this.tooltip = null
  }

  updateText ({ detail: text }) {
    if (this.hasMessageTarget) {
      this.messageTarget.innerHTML = text
    } else if (this.hasMessageValue) {
      this.messageValue = text
    }

    if (this.tooltip) {
      this.tooltip.innerHTML = text
    }
  }
}
