import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    this.element.addEventListener('input', this.autogrow.bind(this))

    this.autogrow()
  }

  autogrow () {
    this.element.style.resize = 'none'
    this.element.style.height = 'auto'
    this.element.style.height = `${this.element.scrollHeight}px`
  }
}
