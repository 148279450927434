import { Controller } from '@hotwired/stimulus'
import { openPopup } from '../utils/share'

export default class extends Controller {
  share ({ params }) {
    if (!params.url) return

    const shareUrl = 'https://www.facebook.com/dialog/share' +
      '?display=popup' +
      '&app_id=431687980220496' +
      `&href=${window.encodeURIComponent(params.url)}` +
      `&quote=${window.encodeURIComponent(params.message)}`

    openPopup(shareUrl)
  }
}
