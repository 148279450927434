import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['prosemirror', 'submitButton']

  initialize () {
    this._beforeSubmitBound = this._beforeSubmit.bind(this)
    this._afterSubmitBound = this._afterSubmit.bind(this)
  }

  connect () {
    this.element.addEventListener('turbo:submit-start', this._beforeSubmitBound)
    this.element.addEventListener('turbo:submit-end', this._afterSubmitBound)
  }

  disconnect () {
    this.element.removeEventListener('turbo:submit-start', this._beforeSubmitBound)
    this.element.removeEventListener('turbo:submit-end', this._afterSubmitBound)
  }

  enable () {
    this.submitButtonTarget.disabled = false
  }

  disable () {
    this.submitButtonTarget.disabled = true
  }

  submit () {
    this.submitButtonTarget.click()
  }

  focus () {
    this.proseMirrorController.focus()
  }

  prependMention (username) {
    this.proseMirrorController.prependMention(username)
  }

  get proseMirrorController () {
    return this.application.getControllerForElementAndIdentifier(this.prosemirrorTarget, 'prosemirror')
  }

  _beforeSubmit (event) {
    this.submitButtonTarget.classList.add('with-loader')
    event.detail.formSubmission.fetchRequest.body.append('comment[body]', this.proseMirrorController.getHTML())
  }

  _afterSubmit (event) {
    this.submitButtonTarget.classList.remove('with-loader')
    if (event.detail.success) {
      this.proseMirrorController.reset()
    }
  }
}
