import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    timeout: {
      type: Number,
      default: 1_000
    }
  }

  initialize () {
    this.throttleTimeout = null
    this.boundSubmit = this.submit.bind(this)
  }

  connect () {
    this.element.addEventListener('input', this.boundSubmit)
  }

  disconnect () {
    this.element.removeEventListener('input', this.boundSubmit)
  }

  submit () {
    clearTimeout(this.throttleTimeout)
    this.throttleTimeout = setTimeout(() => {
      const button = document.createElement('button')
      button.style.display = 'none'
      this.element.appendChild(button)
      button.click()
      setTimeout(() => {
        button.remove()
      }, 0)
    }, this.timeoutValue)
  }
}
