import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    selector: String
  }

  connect () {
    this.element.value = document.querySelector(this.selectorValue).value
  }
}
