import { Controller } from '@hotwired/stimulus'

const CHANNEL = '/notion_sync_state_updated'

export default class extends Controller {
  static targets = ['progress']
  static values = {
    url: String,
    lastMessage: Number
  }

  initialize () {
    this.boundMessageReceived = this.messageReceived.bind(this)
  }

  connect () {
    window.MessageBus.subscribe(CHANNEL, this.boundMessageReceived, this.lastMessageValue)
  }

  disconnect () {
    window.MessageBus.unsubscribe(CHANNEL, this.boundMessageReceived)
  }

  messageReceived (data) {
    if (data.integration_id == null || String(this.urlValue) === '') {
      return
    }

    if (data.completed) {
      const turboFrame = document.querySelector(`turbo-frame#notion_integration_${data.integration_id}`)

      if (turboFrame) {
        turboFrame.src = this.urlValue
      }
    } else if (data.progress) {
      this.dispatch('update', { detail: data.progress })
    }
  }
}
