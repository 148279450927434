import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['input']

  connect () {
    const checked = this.inputTargets.find(({ checked }) => checked)
    this.applyRating(Number(checked.value))
  }

  applyRating (rating) {
    this.inputTargets.forEach((el) => {
      const value = Number(el.value)
      let isActive

      if (rating) {
        isActive = value > 0 && value <= rating
      } else {
        isActive = value === rating
      }

      el.labels[0].classList.toggle('is-active', isActive)
    })
  }

  change (event) {
    this.applyRating(Number(event.target.value))
  }
}
