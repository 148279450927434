/* globals $ */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'options' // <fieldset>
  ]

  initialize () {
    this.lastOptionsChangeDetail = null
  }

  connect () {
    $(this.element).on('closed', () => this.closed())
  }

  open () {
    this.optionsTarget.disabled = false

    $(this.element).trigger('open')
  }

  optionsChange ({ detail: optionsChangeDetail }) {
    this.lastOptionsChangeDetail = optionsChangeDetail
  }

  closed () {
    this.lastOptionsChangeDetail = null
    this.optionsTarget.disabled = true
  }

  confirm () {
    if (this.lastOptionsChangeDetail != null) {
      this.dispatch('confirmed', { detail: this.lastOptionsChangeDetail })
    }

    $(this.element).trigger('close')
  }
}
