import { Controller } from '@hotwired/stimulus'
import { createPopper } from '@popperjs/core'

export default class extends Controller {
  static targets = ['button', 'popover']
  static values = {
    opened: { type: Boolean, default: false }
  }

  initialize () {
    this.boundOutsideClickHandler = this.outsideClickHandler.bind(this)
  }

  connect () {
    this.popper = createPopper(this.buttonTarget, this.popoverTarget)
  }

  disconnect () {
    this.close()

    this.popper.destroy()
    this.popper = null
  }

  toggle () {
    if (this.openedValue) {
      this.close()
    } else {
      this.open()
    }
  }

  open () {
    this.openedValue = true

    setTimeout(() => {
      document.addEventListener('click', this.boundOutsideClickHandler)
    }, 0)

    this.popoverTarget.classList.add('is-visible')
  }

  close () {
    this.openedValue = false

    this.popoverTarget.classList.remove('is-visible')

    document.removeEventListener('click', this.boundOutsideClickHandler)
  }

  outsideClickHandler (event) {
    if (!(event.target === this.popoverTarget || this.popoverTarget.contains(event.target))) {
      this.close()
    }
  }
}
