import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    strings: Array,
    index: Number
  }

  connect () {
    this.reset()
  }

  reset () {
    this.element.innerHTML = '&nbsp;'
    this.append(0)
  }

  append (charIndex) {
    const current = this.stringsValue[this.indexValue % this.stringsValue.length]
    if (charIndex < current.length) {
      this.element.textContent += current[charIndex]
      setTimeout(() => {
        this.append(charIndex + 1)
      }, 150)
    } else {
      setTimeout(() => {
        this.indexValue++
        this.reset()
      }, 3000)
    }
  }
}
