import { Controller } from '@hotwired/stimulus'

const COLOR_SCHEME_MEDIA_QUERY = window.matchMedia('(prefers-color-scheme: dark)')

function loadTheme () {
  return document.cookie.split('; ').find((row) => row.startsWith('theme='))?.split('=')[1]
}

function getPreferredTheme () {
  const storedTheme = loadTheme() ?? 'light'

  if (storedTheme === 'auto') {
    return COLOR_SCHEME_MEDIA_QUERY.matches ? 'dark' : 'light'
  }

  return storedTheme
}

function setTheme (theme) {
  if (theme === 'auto' && COLOR_SCHEME_MEDIA_QUERY.matches) {
    document.documentElement.dataset.theme = 'dark'
  } else {
    document.documentElement.dataset.theme = theme
  }
}

function saveTheme (theme) {
  document.cookie = `theme=${theme}; expires=Fri, 31 Dec 9999 23:59:59 GMT; Path=/`
}

if (document.documentElement.hasAttribute('data-theme')) {
  const listener = () => {
    setTheme(getPreferredTheme())
  }

  if (COLOR_SCHEME_MEDIA_QUERY?.addEventListener) {
    COLOR_SCHEME_MEDIA_QUERY.addEventListener('change', listener)
  } else {
    COLOR_SCHEME_MEDIA_QUERY.addListener(listener)
  }

  setTheme(getPreferredTheme())
}

export default class extends Controller {
  change (event) {
    const theme = event.target.value

    setTheme(theme)
    saveTheme(theme)
  }
}
