/* globals mixpanel */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    eventName: String,
    eventData: Object
  }

  connect () {
    this.observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          mixpanel.track(this.eventNameValue, this.eventDataValue)
          this.element.remove()
        }
      })
    })
    this.observer.observe(this.element)
  }

  disconnect () {
    this.observer.disconnect()
  }
}
