import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['checkbox']

  connect () {
    this.applyState()
  }

  applyState () {
    this.element.classList.toggle('is-active', this.checkboxTarget.checked)
  }

  update () {
    this.applyState()
    this.dispatch('change', { bubbles: true, detail: this.checkboxTarget.checked })
  }
}
