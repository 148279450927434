import { Controller } from '@hotwired/stimulus'
import reflow from 'utils/reflow'
import wrapIndex from 'utils/wrap_index'

const ACTIVE_CLASS = 'is-active'
const ACTIVE_INDICATOR_CLASS = 'is-active'
const DISABLED_BUTTON_CLASS = 'is-disabled'
const INDICATOR_CLASS = 'carousel-indicator'

export default class extends Controller {
  static targets = [
    'prev',
    'next',
    'indicators',
    'indicator',
    'slide'
  ]

  // Callbacks

  initialize () {
    this._isSliding = false
  }

  connect () {
    this._updateControls()
  }

  slideTargetConnected () {
    this._updateControls()

    if (this._getActiveSlideIndex() === -1) {
      this.slideTarget.classList.add(ACTIVE_CLASS)
    }

    if (this.hasIndicatorTarget) {
      this._activateIndicator(this._getActiveSlideIndex())
    }
  }

  slideTargetDisconnected () {
    this._updateControls()
  }

  // Actions

  prev () {
    this._slideBy(-1)
  }

  next () {
    this._slideBy(1)
  }

  goto (event) {
    const activeIndex = this._getActiveSlideIndex()
    const index = this.indicatorTargets.findIndex((el) => el === event.target)

    this._slideBy(index - activeIndex)
  }

  // Public Methods

  switchToLast () {
    this._switchTo(this.slideTargets.length - 1)
  }

  removeActiveSlide () {
    const activeIndex = this._getActiveSlideIndex()
    const activeElement = this.slideTargets[activeIndex]

    if (this.slideTargets.length > 1) {
      this._switchTo(activeIndex === 0 ? 1 : activeIndex - 1)
    }

    activeElement.remove()
  }

  // Private Methods

  _slideBy (offset) {
    if (this._isSliding || offset === 0) {
      return
    }

    const activeIndex = this._getActiveSlideIndex()
    const activeElement = this.slideTargets[activeIndex]
    const nextIndex = wrapIndex(activeIndex + offset, this.slideTargets.length)
    const nextElement = this.slideTargets[nextIndex]
    const orderClass = offset > 0 ? 'is-next' : 'is-prev'
    const directionClass = offset > 0 ? 'is-start' : 'is-end'

    this._isSliding = true

    nextElement.classList.add(orderClass)
    reflow(nextElement)
    nextElement.classList.add(directionClass)
    activeElement.classList.add(directionClass)

    this._activateIndicator(nextIndex)

    nextElement.addEventListener('transitionend', () => {
      nextElement.classList.remove(directionClass, orderClass)
      nextElement.classList.add(ACTIVE_CLASS)
      activeElement.classList.remove(ACTIVE_CLASS, directionClass, orderClass)

      this._isSliding = false
    }, { once: true })
  }

  _switchTo (index) {
    if (this._isSliding) {
      return
    }

    const activeIndex = this._getActiveSlideIndex()

    if (activeIndex !== -1) {
      const activeElement = this.slideTargets[activeIndex]
      activeElement.classList.remove(ACTIVE_CLASS)
    }

    const nextElement = this.slideTargets[index]
    nextElement.classList.add(ACTIVE_CLASS)

    // HACK: Activate indicator after changing number of indicators
    setTimeout(() => this._activateIndicator(this._getActiveSlideIndex()), 0)
  }

  _updateButtons () {
    const shouldDisable = this.slideTargets.length < 2

    this.prevTarget.classList.toggle(DISABLED_BUTTON_CLASS, shouldDisable)
    this.nextTarget.classList.toggle(DISABLED_BUTTON_CLASS, shouldDisable)
  }

  _updateIndicators () {
    if (!this.hasIndicatorsTarget) return

    const indicatorsCount = this.indicatorTargets.length
    let slideCount = this.slideTargets.length

    if (slideCount === 1) slideCount = 0

    if (indicatorsCount < slideCount) {
      for (let i = 0; i < slideCount - indicatorsCount; i++) {
        this.indicatorsTarget.appendChild(this._createIndicator())
      }
    } else if (indicatorsCount > slideCount) {
      this.indicatorTargets.slice(slideCount - indicatorsCount).forEach((el) => el.remove())
    }
  }

  _updateControls () {
    this.element.classList.toggle('is-empty', !this.hasSlideTarget)
    this._updateButtons()
    this._updateIndicators()
  }

  _activateIndicator (index) {
    if (!this.hasIndicatorTarget) return

    let activeIndicator = this.indicatorTargets.find((el) => el.classList.contains(ACTIVE_INDICATOR_CLASS))

    if (activeIndicator) {
      activeIndicator.classList.remove(ACTIVE_INDICATOR_CLASS)
    }

    activeIndicator = this.indicatorTargets[index]

    if (activeIndicator) {
      activeIndicator.classList.add(ACTIVE_INDICATOR_CLASS)
    }
  }

  _getActiveSlideIndex () {
    return this.slideTargets.findIndex((el) => el.classList.contains(ACTIVE_CLASS))
  }

  _createIndicator () {
    const dot = document.createElement('div')
    dot.classList.add(INDICATOR_CLASS)
    dot.dataset.carouselTarget = 'indicator'
    dot.dataset.action = 'click->carousel#goto'

    return dot
  }
}
