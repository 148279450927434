import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    target: String
  }

  static classes = ['css']

  getTargetElement () {
    if (!this.targetValue) {
      return this.element
    }

    return document.querySelector(this.targetValue)
  }

  toggle () {
    const el = this.getTargetElement()

    if (!el) return

    this.cssClasses.forEach((cssClass) => el.classList.toggle(cssClass))
  }

  add () {
    const el = this.getTargetElement()

    if (!el) return

    el.classList.add(...this.cssClasses)
  }

  remove () {
    const el = this.getTargetElement()

    if (!el) return

    el.classList.remove(...this.cssClasses)
  }
}
