import { Controller } from '@hotwired/stimulus'

const COLOR_MODIFIERS = ['turquoise', 'purple', 'orange', 'yellow', 'pink', 'green', 'red']

export default class extends Controller {
  static targets = ['item']

  connect () {
    this.itemTargets.forEach((el) => el.classList.add(`is-${this.getColor(el.textContent)}`))
  }

  getColor (text) {
    return COLOR_MODIFIERS[text.length % COLOR_MODIFIERS.length]
  }
}
