/* globals $, evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['error']
  static values = {
    url: String,
    options: Object
  }

  connect () {
    if (!this.urlValue) throw Error('URL is not provided')

    this.visibilityObserver = new IntersectionObserver((entries, io) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          io.unobserve(this.element)
          this.loadContent()
        }
      })
    })
    this.visibilityObserver.observe(this.element)
  }

  disconnect () {
    this.visibilityObserver.disconnect()
    this.currentRequest?.abort()
  }

  loadContent () {
    const { method, data } = this.optionsValue

    this.currentRequest = $.rails.ajax({
      url: this.urlValue,
      dataType: 'html',
      method,
      data,
      success: (responseText) => {
        const newContent = $(responseText)

        $(this.element).replaceWith(newContent)
        evil.block.vitalize(newContent.parent())
      },
      error: () => {
        if (this.hasErrorTarget) {
          $(this.element).replaceWith($(this.errorTarget).html())
        }
      },
      complete: () => {
        this.currentRequest = null
      }
    })
  }
}
