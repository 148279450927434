import { Controller } from '@hotwired/stimulus'
import { openPopup } from '../utils/share'

export default class extends Controller {
  share ({ params }) {
    const shareUrl = `https://api.whatsapp.com/send?text=${window.encodeURIComponent(params.message)}`

    openPopup(shareUrl, { width: 748, height: 720 })
  }
}
