import { Controller } from '@hotwired/stimulus'
import { decode } from 'blurhash'

export default class extends Controller {
  connect () {
    const hash = this.element.dataset.blurhash
    const domRect = this.element.getBoundingClientRect()
    const width = Math.floor(domRect.width)
    const height = Math.floor(domRect.height)
    const pixels = decode(hash, width, height)

    const canvas = document.createElement('canvas')
    canvas.width = width
    canvas.height = height

    const drawContext = canvas.getContext('2d')
    const imageData = drawContext.createImageData(width, height)
    imageData.data.set(pixels)
    drawContext.putImageData(imageData, 0, 0)

    this.element.style.backgroundImage = `url(${canvas.toDataURL('image/png')})`
  }
}
