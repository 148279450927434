import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = ['toggle', 'input']

  initialize () {
    this.selected = new Set()
  }

  connect () {
    try {
      const data = JSON.parse(this.element.dataset.selected)

      data.forEach((id) => this.add(String(id)))
    } catch {}
  }

  toggleTargetConnected (el) {
    const id = String(el.value || el.dataset.id)

    el.checked = this.selected.has(id)
  }

  toggle (event) {
    const el = event.currentTarget
    const id = String(el.value || el.dataset.id)

    if (el.checked) {
      this.add(id)
    } else {
      this.remove(id)
    }
  }

  add (id) {
    this.selected.add(id)

    const newEl = this.inputTargets[0].cloneNode()
    newEl.id += id
    newEl.value = id

    this.inputTargets[0].insertAdjacentElement('afterend', newEl)
  }

  remove (id) {
    this.selected.delete(id)

    const el = document.getElementById(this.inputTargets[0].id + id)

    if (el) {
      el.remove()
    }
  }
}
