import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    const valueEl = document.createElement('span')
    valueEl.classList.add('form-select-value')

    this.valueElement = valueEl

    this.element.classList.add('is-transparent')
    this.element.parentNode.insertBefore(this.valueElement, this.element)

    this.applyState()
  }

  disconnect () {
    this.element.classList.remove('is-transparent')
    this.element.parentNode.removeChild(this.valueElement)

    delete this.valueElement
  }

  change (_event) {
    this.applyState()
  }

  applyState () {
    const selectedOption = this.element.selectedOptions[0]

    if (selectedOption == null) return

    this.valueElement.textContent = selectedOption.textContent
  }
}
