// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

import { Turbo } from '@hotwired/turbo-rails'
import { ResizeObserver as ResizeObserverPolyfill } from '@juggle/resize-observer'
import application from 'controllers'

Turbo.session.drive = false

window.ResizeObserver ||= ResizeObserverPolyfill

function importAll (r) {
  r.keys().forEach(r)
}

importAll(require.context('../icons', true))
importAll(require.context('../images', true))

Object.assign(Turbo.StreamActions, {
  close_modal () {
    this.targetElements.forEach((el) => {
      el.dispatchEvent(new CustomEvent('close'))
    })
  },
  close_popover () {
    this.targetElements.forEach((el) => {
      application.getControllerForElementAndIdentifier(el, 'popover').close()
    })
  },
  redirect () {
    const location = this.getAttribute('location')
    if (location) {
      setTimeout(() => window.location.assign(location))
    }
  },
  reload () {
    window.location.reload()
  }
})
