/* globals evil */

import { Controller } from '@hotwired/stimulus'

function cueMarkObserverCallback (entries) {
  entries.forEach((entry) => {
    if (entry.isIntersecting) {
      this.requestPage(entry.target.dataset.url)
    }
  })
}

export default class extends Controller {
  static values = {
    expanded: Boolean,
    count: Number
  }

  static targets = [
    'listWrapper',
    'list',
    'item',
    'skeleton',
    'cueMark'
  ]

  initialize () {
    this.currentRequest = null
  }

  connect () {
    this.cueMarkObserver = new IntersectionObserver(cueMarkObserverCallback.bind(this))

    if (this.hasCueMarkTarget) {
      this.cueMarkObserver.observe(this.cueMarkTarget)
    }
  }

  disconnect () {
    this.cueMarkObserver.disconnect()
  }

  expandedValueChanged () {
    this.element.classList.toggle('is-expanded', this.expandedValue)

    if (this.expandedValue) {
      if (this.hasSkeletonTarget) {
        let inserted = this.skeletonTargets.length
        const width = this.listWrapperTarget.offsetWidth
        const skeleton = this.skeletonTarget.cloneNode(true)

        skeleton.dataset.horizontalBookListTarget = 'skeleton'

        while (inserted < this.countValue && this.listTarget.offsetWidth < width) {
          this.listTarget.appendChild(skeleton.cloneNode(true))
          inserted++
        }
      }
    }
  }

  toggleAction (event) {
    let node = event.target
    do {
      if (node.tagName === 'A') return
      node = node.parentNode
    } while (node != null)

    this.toggle()
  }

  toggle () {
    this.expandedValue = !this.expandedValue
  }

  requestPage (url) {
    if (!url) throw Error('URL is not provided')

    if (!this.currentRequest) {
      this.currentRequest = $.ajax({
        url,
        success: (responseText) => {
          this.skeletonTargets.forEach((el) => el.remove())
          this.listTarget.insertAdjacentHTML('beforeend', responseText)

          if (this.hasCueMarkTarget) {
            this.cueMarkObserver.observe(this.cueMarkTarget)
          }

          this.listWrapperTarget.classList.add('is-scrollable')

          evil.block.vitalize(this.listTarget)

          this.dispatch('change')
        },
        complete: () => {
          this.currentRequest = null
        }
      })
    }

    return this.currentRequest
  }
}
