import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static targets = [
    'visibilityOption', // <input type="radio">
    'friendListsContainer', // <turbo-frame>
    'friendListOption' // <input type="check">
  ]

  visibilityChange (event) {
    this.toggleFriendLists(event.target.value === 'friend_lists')
    this.change()
  }

  toggleFriendLists (force) {
    this.friendListsContainerTarget.hidden = !force
  }

  change () {
    const checkedRadio = this.visibilityOptionTargets.find(({ checked }) => checked)

    const friendListIds = []
    if (checkedRadio.value === 'friend_lists') {
      this.friendListOptionTargets.forEach(({ value, checked }) => {
        if (!checked) return

        friendListIds.push(value)
      })
    }

    this.dispatch('change', {
      detail: {
        visibility: checkedRadio.value,
        label: checkedRadio.dataset.text,
        friendListIds
      }
    })
  }
}
