import { Controller } from '@hotwired/stimulus'

const SIZE = 24
const CENTER = SIZE / 2
const WIDTH = 3
const RADIUS = CENTER - Math.round(WIDTH / 2)

export default class extends Controller {
  static values = {
    progress: Number
  }

  connect () {
    if (this.element.firstChild) return

    const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')

    svg.setAttributeNS(null, 'width', `${SIZE}px`)
    svg.setAttributeNS(null, 'height', `${SIZE}px`)

    const background = document.createElementNS('http://www.w3.org/2000/svg', 'circle')

    background.setAttributeNS(null, 'stroke-width', WIDTH)
    background.setAttributeNS(null, 'cx', CENTER)
    background.setAttributeNS(null, 'cy', CENTER)
    background.setAttributeNS(null, 'r', RADIUS)

    const circumference = 2 * Math.PI * RADIUS
    const progressPercent = (this.progressValue ? Math.ceil(this.progressValue) / 100 : 0.01)
    const dasharray = circumference * progressPercent
    const dashoffset = circumference - dasharray
    const track = background.cloneNode()

    track.setAttributeNS(null, 'stroke-linecap', 'round')
    track.setAttributeNS(null, 'stroke-dasharray', circumference)
    track.setAttributeNS(null, 'stroke-dashoffset', circumference)
    track.setAttributeNS(null, 'transform', `rotate(-90, ${CENTER}, ${CENTER})`)

    svg.appendChild(background)
    svg.appendChild(track)

    this.element.appendChild(svg)

    setTimeout(() => { track.style.strokeDashoffset = dashoffset }, 0) // To trigger animation
  }

  disconnect () {
    while (this.element.firstChild) {
      this.element.removeChild(this.element.firstChild)
    }
  }
}
