/* globals evil */

import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  connect () {
    evil.block.vitalize(this.element.parentElement)
    this.element.remove()
  }
}
